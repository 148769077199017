import * as io from "socket.io-client";
import {CancelTravelInfoData, DriverStatus, IDriverData, RideNowTravelInfoData, SendDataEvent} from "../helpers/interface.helpers";
import {StatusTripEnum} from "../enums/driver.enums";

const REZGLO_API_SOCKET = process.env.REZGLO_API_SOCKET;

class EchoProvider {
    public io: any;
    public uniqueBrowser: string;
    public unique: string;
    public randomUniqueNumber: string;
    public socketUrl: string;
    public isConnectedDriver: boolean;
    public driverStatus: DriverStatus;

    constructor() {
        this.uniqueBrowser = Math.random().toString();
        this.unique = Math.random().toString();
        this.randomUniqueNumber = '';
        this.isConnectedDriver = false;
        this.driverStatus = "F";
        this.socketUrl = `${REZGLO_API_SOCKET}?origin=rezglo&unique=${this.unique}&deviceFrom=${this.uniqueBrowser}`;
    };

    public initialize = ({
        id,
        cancelTravelCallback,
        rideNowTripCallback,
    }: {
        id: any;
        cancelTravelCallback: (isSameBooking: boolean, description: string)=>void;
        rideNowTripCallback: (bookingId: string)=>void;
    })=>{
        this.connect({id, cancelTravelCallback, rideNowTripCallback});
        this.disconnect();
        this.observerDriverLocation();
        this.observerDriverDisable();
    }

    public connect = ({
        id, 
        connectCallback,
        cancelTravelCallback,
        rideNowTripCallback,
    }:{
        id: number | string;
        connectCallback?: () => void;
        cancelTravelCallback: (isSameBooking: boolean, description: string)=>void;
        rideNowTripCallback: (bookingId: string)=>void;
    }) => {
        this.io = io.connect(
            this.socketUrl,
            {
                reconnection: true,
                reconnectionDelay: 5000,
                reconnectionDelayMax: 20000,
                reconnectionAttempts: Infinity,
                transports: ['websocket'],
            },
        );
        this.io.on('connect', () => {
            this.isConnectedDriver = true;
            const obj = {
                unique: this.unique,
                deviceFrom: this.uniqueBrowser,
                platform: 'WEB',
                user_type: 'D',
                id,
            };
            this.io.emit('SocketAuthentication', obj, data => {
                this.randomUniqueNumber = data.randomUniqueNumber;
                connectCallback && connectCallback();
            });
            
            if(!this.io._callbacks['$CANCEL_TRAVEL_INFO']){
                this.io.on('CANCEL_TRAVEL_INFO', (data: CancelTravelInfoData) => {
                    this.handleCanceledTripEvent(data, cancelTravelCallback)
                });
            };

            if(!this.io._callbacks['$RIDE_NOW_TRAVEL_INFO']) {
                this.io.on('RIDE_NOW_TRAVEL_INFO', (data: RideNowTravelInfoData)=>{
                    const { booking_id } = data;
                    rideNowTripCallback(booking_id);
                })
            }
        });  
    };

    public sendData = (event: SendDataEvent, data?: IDriverData, callback?: (response?: any) => void) => {
        const {driverData: {bookingId, company_id, is_subscribed_company, userid, model_name, name, taxi_id,shift_id,companyAdminId, passenger_id}, location, driverStatus} = data;
        this.driverStatus = driverStatus;

        // The "driver_location_history_update" event updates the API databse and the map, and the
        // "driver_location_share" updates the driver's position and status in the map

        const eventData = {
            "data": {
                "driver_id": String(userid),
                "trip_id": bookingId,
                "locations": `${location?.lat},${location?.lng}|`,
                "status": driverStatus || "F",
                "travel_status": "",
                "device_token": "49356b2176588b77",
                "device_type": "1",
                "above_min_km": "0",
                "bearings": 11.131209373474121,
                "distance": "0.0",
                "shift_id": shift_id,
                "driver_name": name,
                "driver_taxi_number": taxi_id,
                "driver_taxi_model": model_name,
                "waiting_hour": "0.0",
                "company_id": company_id,
                "is_subscribed_company": is_subscribed_company,
                "companyAdminId":companyAdminId,
            },
            "unique": this.unique,
            "platform": "ANDROID",
            "app": "DRIVER",
            "id": String(userid)
        };
        if(passenger_id) {
            eventData['data']['passenger_id'] = passenger_id
        };
        this.io.emit(event, {...eventData}, (response) => {
            callback && callback(response);
        });
    }

    public disconnect = (callback?: (response) => void) => {
        this.io.on('disconnect', (response) => {
            this.isConnectedDriver = false;
            callback && callback(response);
        });
    }

    public observerDriverLocation = (callback?: (response?: any) => void) => {
        this.io.on('DRIVERS_CURRENT_LOCATION', (response) => {
            callback && callback(response);
        });
    }

    public observerDriverDisable = (callback?: (response?: any) => void) => {
        this.io.on('DRIVER_DISABLE_TRIGGER', (response) => {
            this.isConnectedDriver = false;
            callback && callback(response);
        });
    };

    public updateDriverStatus(driverId, callback?: (response: any) => void) {

        this.io.emit('DRIVER_DISABLE', {
            "driver_id": String(driverId),
            "process": "SHIFT_OUT",
            "unique": this.unique,
        }, (response) => {
            this.isConnectedDriver = false;
            callback && callback(response);
        });
    };

    public getDriverStatus(tripStatus: string, booking: any){
        const storedStatus = localStorage.getItem('driver-status');
        if(storedStatus === 'B') return 'B';
        switch (tripStatus) {
            case StatusTripEnum.SHARED_POSITION:
                return "F"
            case StatusTripEnum.ARRIVED:
                if (booking && !booking.is_ride_now) {
                    return "F"
                } else {
                    return "B"
                }
            case StatusTripEnum.START_TRIP:
                return "B"
            case StatusTripEnum.END_TRIP:
                return "B"
            case StatusTripEnum.FINISH:
                return "F"
            case StatusTripEnum.CANCELED:
                return "F"
        }
    };

    handleCanceledTripEvent = (data: CancelTravelInfoData, callback: (isSameBooking: boolean, description: string)=>void)=>{
        const { booking_id, reason } = data;
        const urlParams = new URLSearchParams(window.location.search);
        const tripId = urlParams.get('trip');
        const formattedReason = this.getCancelingReason(reason);
        if(tripId === booking_id) {
            callback(true, `This trip ${formattedReason}. You will be redirected to the Home page`);
        } else {
            callback(false, `Trip ${booking_id} ${formattedReason}`);
        };
    };

    getCancelingReason = (reason: string)=>{
        switch (reason) {
            case 'Cancel Booking':
                return 'was cancelled from the Dispatch';
            case 'trip_canceled_deleted_passenger_account':
                return "was cancelled because the passenger's account was deleted";
            case 'canceled_by_passenger':
                return 'was cancelled by the passenger';
            default:
                return 'was cancelled'
        }
    }
}

export default EchoProvider;
